<template>
    <div>
        <p class="title is-1">{{ $t("titles.Resume") }}</p>
        <div class="card">
            <div class="card-content">
                <p class="title is-3 has-text-left">{{ $t("labels.Experiences") }}</p>
                <ResumeLine v-for="l in experiences" :key="l.i18nName" :i18nName="'resume.experiences.' + l.i18nName" :paragraphNumber="l.paragraphNumber" />
            </div>
        </div>
        <br />
        <div class="card">
            <div class="card-content">
                <p class="title is-3 has-text-left">{{ $t("labels.Diplomas") }}</p>
                <ResumeLine v-for="l in diplomas" :key="l.i18nName" :i18nName="'resume.diplomas.' + l.i18nName" :paragraphNumber="l.paragraphNumber" />
            </div>
        </div>
        <br />
        <div class="card">
            <div class="card-content">
                <p class="title is-3 has-text-left">{{ $t("labels.Hobbies") }}</p>
                <div class="columns is-multiline">
                    <div v-for="h in hobbies" :key="h" class="column is-half" style="padding: 0">
                        <div class="card" style="background-color: #555555">
                            <div class="card-content">
                                <p class="title is-3">{{ $t(`resume.hobbies.${h}.name`) }}</p>
                                <p class="has-text-left">{{ $t(`resume.hobbies.${h}.detail`) }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ResumeLine from "../components/ResumeLine.vue";

export default {
    name: "Resume",
    components: {
        ResumeLine,
    },
    data() {
        return {
            experiences: [
                {
                    i18nName: "SopraJobGammePilot",
                    paragraphNumber: 3,
                },
                {
                    i18nName: "EIWorkStudy",
                    paragraphNumber: 3,
                },
                {
                    i18nName: "GRETAInternship",
                    paragraphNumber: 2,
                },
                {
                    i18nName: "HDBInternship",
                    paragraphNumber: 2,
                },
            ],
            diplomas: [
                {
                    i18nName: "MasterMIAGE",
                    paragraphNumber: 4,
                },
                {
                    i18nName: "L3MIAGE",
                    paragraphNumber: 4,
                },
                {
                    i18nName: "BTSSIO",
                    paragraphNumber: 3,
                },
                {
                    i18nName: "BacSTI2D",
                    paragraphNumber: 2,
                },
            ],
            hobbies: ["Roleplay", "CS", "Cooking", "Games", "Music"],
        };
    },
};
</script>
