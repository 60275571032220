<template>
    <div>
        <p class="title is-1">{{ $t("titles.Projects") }}</p>
        <div class="columns is-multiline">
            <router-link class="column is-half" v-for="p in projects" :key="p.name" :to="p.url">
                <div class="card">
                    <div v-if="p.image" class="card-image">
                        <figure class="image is-4by3">
                            <img :src="p.image" alt="Placeholder image" />
                        </figure>
                    </div>
                    <div class="card-content">
                        <div class="media">
                            <div class="media-content">
                                <p class="title is-4">{{ p.name }}</p>
                            </div>
                        </div>

                        <div class="content">{{ p.detail }}</div>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            projects: [
                {
                    name: "Portfolio",
                    detail: "This portfolio",
                    image: "",
                    url: "/Portfolio",
                },
                {
                    name: "Cyberbonk",
                    detail: "Game with unity",
                    image: "",
                    url: "/Cyberbonk",
                },
            ],
        };
    },
};
</script>

<style>
.card {
    background-color: #444444;
    margin: 0.5em;
    box-sizing: border-box;
    color: white;
}
</style>
