<template>
    <div>
        <p class="title is-1">Le Vanane</p>
        <div class="has-text-left">
            <p>&emsp;&emsp;&emsp;{{ $t("homePage.Welcome1") }}</p>
            <p>{{ $t("homePage.Welcome2") }}</p>
            <p>{{ $t("homePage.Welcome3") }}</p>
            <p>{{ $t("homePage.Welcome4") }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "App",
};
</script>
