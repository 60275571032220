import { createI18n } from "vue-i18n";

const messages = {
    /* Ajouter ici les langages et les traductions supplémentaires */
    /* Exemple de format */
    xx: {
        titles: {
            Home: "",
            Projects: "",
            Resume: "",
            About: "",
            Vananments: "{count}",
        },

        labels: {
            Experiences: "",
            Diplomas: "",
            Hobbies: "",
        },

        homePage: {
            Welcome1: "",
            Welcome2: "",
            Welcome3: "",
            Welcome4: "",
        },
        resume: {
            experiences: {
                SopraJobGammePilot: {
                    name: "",
                    period: "",
                    paragraphs: {
                        p1: "",
                        p2: "",
                        p3: "",
                    },
                },
                EIWorkStudy: {
                    name: "",
                    period: "",
                    paragraphs: {
                        p1: "",
                        p2: "",
                        p3: "",
                    },
                },
                GRETAInternship: {
                    name: "",
                    period: "",
                    paragraphs: {
                        p1: "",
                        p2: "",
                    },
                },
                HDBInternship: {
                    name: "",
                    period: "",
                    paragraphs: {
                        p1: "",
                        p2: "",
                    },
                },
            },

            diplomas: {
                MasterMIAGE: {
                    name: "",
                    period: "",
                    paragraphs: {
                        p1: "",
                        p2: "",
                        p3: "",
                        p4: "",
                    },
                },
                L3MIAGE: {
                    name: "",
                    period: "",
                    paragraphs: {
                        p1: "",
                        p2: "",
                        p3: "",
                        p4: "",
                    },
                },
                BTSSIO: {
                    name: "",
                    period: "",
                    paragraphs: {
                        p1: "",
                        p2: "",
                        p3: "",
                    },
                },
                BacSTI2D: {
                    name: "",
                    period: "",
                    paragraphs: {
                        p1: "",
                        p2: "",
                    },
                },
            },
            hobbies: {
                Roleplay: {
                    name: "",
                    detail: "",
                },
                CS: {
                    name: "",
                    detail: "",
                },
                Cooking: {
                    name: "",
                    detail: "",
                },
                Games: {
                    name: "",
                    detail: "",
                },
                Music: {
                    name: "",
                    detail: "",
                },
            },
        },
    },

    en: {
        titles: {
            Home: "Home",
            Projects: "Projects",
            Resume: "Resume",
            About: "About",
            Vananments: "The {count} Vananments",
        },

        labels: {
            Experiences: "Experiences",
            Diplomas: "Diplomas",
            Hobbies: "Hobbies",
        },

        homePage: {
            Welcome1: "Hello there !",
            Welcome2: "I'm Vanane, but you can call me Vanane (that's not my real name). This website is my portfolio, and also a test area. Here I reference (and totally show off) my personal projects and ideas.",
            Welcome3: "I'm a former student with a major in CS and a minor in project management. Most of my projects are games and softwares, but I'm also interested in pixel art and 3D modelization.",
            Welcome4: "Feel free to hang around !",
        },
        resume: {
            experiences: {
                SopraJobGammePilot: {
                    name: "Developer analyst for Sopra Steria in Nantes, France",
                    period: "November 2022 - Still going",
                    paragraphs: {
                        p1: "Contract in the Energy sector of the company",
                        p2: "Evolving maintenance of a set of applications owned by Enedis (Gamme-Pilot)",
                        p3: "PHP, SQL & KSH / Use of tools for software industrialization & DevOps (Jenkins, Sonar)",
                    },
                },
                EIWorkStudy: {
                    name: "Developer analyst as work-study at Euro-Information in Nantes, France",
                    period: "October 2020 - September 2022",
                    paragraphs: {
                        p1: "2-year contract in the context of Master MIAGE.",
                        p2: "Several projects related to manual testing and automation.",
                        p3: "Development of a tool allowing for a user to record their activity on a web page, and generate both a test scenario and a ALM UFT or Playwright script.",
                    },
                },
                GRETAInternship: {
                    name: "Developer analyst internship at GRETA in Nantes, France",
                    period: "December 2018 - February 2019",
                    paragraphs: {
                        p1: "2 months-long internship in the context of BTS SIO.",
                        p2: "Development of a mock ERP and inventory management system, for educational purposes.",
                    },
                },
                HDBInternship: {
                    name: "Web developer internship at HDB Technology in Saint-Nazaire, France",
                    period: "May 2018 - June 2018",
                    paragraphs: {
                        p1: "2 months-longinternship in the context of BTS SIO.",
                        p2: "Development of a GUI Web element library that can be inserted to a web page manually.",
                    },
                },
            },

            diplomas: {
                MasterMIAGE: {
                    name: "Master degree of MIAGE at University of Nantes, France",
                    period: "2020 - 2022",
                    paragraphs: {
                        p1: "Design patterns, dynamic loading, advanced software architecture knowledge",
                        p2: "Project planning and management, team management, Agility methodology",
                        p3: "Phases of a project : collecting the needs, formalizing them, product conception and development, testing, deployment",
                        p4: "Network protocols, development of sockets and protocols",
                    },
                },
                L3MIAGE: {
                    name: "Third year of Bachelor Degree of MIAGE at University of Nantes, France",
                    period: "2019 - 2020",
                    paragraphs: {
                        p1: "Data structures (FIFO, LIFO, etc), low-level programming (C, COBOL)",
                        p2: "Advanced electronics, computer architecture, computer modelisation",
                        p3: "Management control, financial management",
                        p4: "Internet protocols and mechanisms",
                    },
                },
                BTSSIO: {
                    name: "Associate Degree in Computer Science (BTS SIO) at Lycée Carcouet in Nantes, France",
                    period: "2017 - 2019",
                    paragraphs: {
                        p1: "OOP languages (C#, PHP), database modelisation (Merise and SQL), Mathematics regarding computer science",
                        p2: "Basics in business and financial management, management control",
                        p3: "basics in networks",
                    },
                },
                BacSTI2D: {
                    name: "High School diploma in Computer Science (STI2D) at Lycée Artiste Briand in Saint-Nazaire",
                    period: "2017",
                    paragraphs: {
                        p1: "Basics in electronics and high-level programming (Java)",
                        p2: "Fundamentals in computer science, training on Processing and Arduino cards",
                    },
                },
            },

            hobbies: {
                Roleplay: {
                    name: "Tabletop roleplaying",
                    detail: "i'm in love with roleplaying games, whether it's tabletop or video games. I played in several campaigns, notably D&D, Naheulbeuk, and a french system called Aventures. I also used to play in custom futuristic and cyberpunk-ish universes.",
                },
                CS: {
                    name: "Computer science",
                    detail: "I've got a passion for computers in general. I learning new things everyday on very various topics, ranging from specifics in a language, to whole programming paradigms, or even hardware topics, like architecture standards.",
                },
                Cooking: {
                    name: "Fud",
                    detail: "I like fud, I'm interested in getting better at cooking. I try to train every day by discovering new recipes, whether they're exotic or basic.",
                },
                Games: {
                    name: "Video games",
                    detail: "I love video games, too, and specifically RPG (I loved Cyberpunk). I also enjoy multiplayer games with friends, such ad Deep Rock Galactic, Stellaris, Northgard, etc; I'm not a big fan of 4X and RTS, except with friends.",
                },
                Music: {
                    name: "Metal, Synthwave, Punk...",
                    detail: "...Among others",
                },
            },
        },
    },

    fr: {
        titles: {
            Home: "Maisonnée",
            Projects: "Projets",
            Resume: "Résumé",
            About: "A propos",
            Vananments: "Les {count} Vananements",
        },

        labels: {
            Experiences: "Expériences",
            Diplomas: "Diplomes",
            Hobbies: "Centres d'intérêt",
        },

        homePage: {
            Welcome1: "Bonjour !",
            Welcome2:
                "Moi c'est Vanane, mais vous pouvez m'appeler Vanane (ce n'est pas mon vrai prénom). Ce site web est mon portfolio, ainsi qu'une sorte de zone de test. Je référencie ici (et frime carrément avec) mes projets personnels.",
            Welcome3:
                "Je suis un ancien étudiant en MIAGE, un diplôme d'informatique pour les entreprises et de gestion de projet/financière. La majorité de mes projets sont des (prototypes de) jeux et des logiciels, mais je suis aussi intéressé par le pixel art et la modélisation 3D. ",
            Welcome4: "Bonne visite ! Ou un truc comme ça.",
        },
        resume: {
            experiences: {
                SopraJobGammePilot: {
                    name: "Analyste-développeur en CDI chez Sopra Steria à Nantes, France",
                    period: "Novembre 2022 - En cours",
                    paragraphs: {
                        p1: "CDI à Nantes dans le secteur Energie",
                        p2: "Maintenance évolutive d'applications Gamme-Pilot pour le compte d'Enedis",
                        p3: "PHP, SQL et KSH / Utilisation d'outils de développement industrialisé & DevOps (Jenkins, Sonar)",
                    },
                },
                EIWorkStudy: {
                    name: "Analyste-développeur Alternant chez Euro-Information à Nantes, France",
                    period: "Octobre 2020 - Septembre 2022",
                    paragraphs: {
                        p1: "Alternance de deux ans pour le diplôme de MIAGE.",
                        p2: "Multiples projets dans le secteur de la qualification logicielle.",
                        p3: "Développement d'un outil permettant d'enregistrer les actions utilisateur sur une page internet, en vue de restituer un scénario de test convrtible en script Playwright ou ALM UFT.",
                    },
                },
                GRETAInternship: {
                    name: "Stage d'étude au GRETA de Nantes, France",
                    period: "Decembre 2018 - Février 2019",
                    paragraphs: {
                        p1: "Stage de deux mois pour le diplôme de BTS SIO.",
                        p2: "Réalisation d'une application Web de gestion de stocks, simplifiée pour un usage pédagogique.",
                    },
                },
                HDBInternship: {
                    name: "Stage d'étude à HDB Technology à Saint-Nazaire, France",
                    period: "Mai 2018 - Juin 2018",
                    paragraphs: {
                        p1: "Stage de deux mois pour le diplôme de BTS SIO.",
                        p2: "Réalisation d'une librairie interne d'éléments Web à intégrer manuellement dans une page Web.",
                    },
                },
            },

            diplomas: {
                MasterMIAGE: {
                    name: "Master MIAGE à l'Université de Nantes, France",
                    period: "2020 - 2022",
                    paragraphs: {
                        p1: "Design patterns, dynamic loading, architectures logicielles avancées",
                        p2: "Gestion et planification de projet, gestion d'équipe (Agilité, Lean, XP...)",
                        p3: "Etapes d'un projet : définition d'un besoin, conception d'une solution, organisation du développement, tests logiciels, mise en production",
                        p4: "Réseaux informatiques, écriture d'un socket, d'un protocole",
                    },
                },
                L3MIAGE: {
                    name: "Licence MIAGE à l'Université de Nantes, France",
                    period: "2019 - 2020",
                    paragraphs: {
                        p1: "Structures de données, programmation bas niveau (C, COBOL, Assembleur)",
                        p2: "Electronique avancée, architecture matérielle, modélisation du matériél",
                        p3: "Contrôle de gestion et gestion financière (bilan fonctionnel, trésorerie)",
                        p4: "Réseaux informatiques, protocoles IP, TCP...",
                    },
                },
                BTSSIO: {
                    name: "BTS SIO au lycée Carcouët de Nantes, France",
                    period: "2017 - 2019",
                    paragraphs: {
                        p1: "Bases solides en C# et en PHP, modélisation Merise et SQL, mathématiques de l'informatique",
                        p2: "Gestion d'entreprise, contrôle de gestion",
                        p3: "Bases des réseaux informatiques",
                    },
                },
                BacSTI2D: {
                    name: "BAC STI2D au lycée Aristide Briand à Saint-Nazaire, France",
                    period: "2016 - 2017",
                    paragraphs: {
                        p1: "Bases en électronique et en programmation de haut-niveau",
                        p2: "Utilisation de Processing et de cartes Arduino, calcul binaire, connaissances fondamentales d'informatique",
                    },
                },
            },

            hobbies: {
                Roleplay: {
                    name: "Les jeux de rôles",
                    detail: "J'adore les jeux de rôle, sur table ou en jeux vidéo. J'ai fait notamment du D&D, Naheulbeuk et Aventures, ainsi que quelques parties sur des univers cyberpunk/futuristes.",
                },
                CS: {
                    name: "L'informatique",
                    detail: "Je suis passionné d'informatique, au sens large du terme. J'ai un esprit très curieux, et je découvre régulièrement des notions, que ce soit en programmation, ou bien en création d'application ou de jeu, ou même sur le fonctionnement d'un OS, tout m'intéresse.",
                },
                Cooking: {
                    name: "La bouffe",
                    detail: "J'aime bien manger. J'aime aussi cuisiner, j'ai développé un certain intérêt dans la cuisine au fil du temps, et j'essaie de pratiquer autant que possible.",
                },
                Games: {
                    name: "Les jeux vidéo",
                    detail: "J'adore les jeux vidéo, en particulier les RPG (Skyrim, Cyberpunk dernièrement), et les jeux fun entre amis (Deep Rock Galactic, Northgard, Stellaris, Anno), entre autres. Pas très fan de RTS/4X en-dehors des parties entre copains.",
                },
                Music: {
                    name: "Le Metal, Synthwave, Punk...",
                    detail: "...Et plein d'autres choses",
                },
            },
        },
    },
};

const savedLocale = localStorage.getItem("locale");

const i18n = createI18n({
    locale: savedLocale ?? "fr",
    fallbackLocale: "fr",
    messages: messages,
});

export default i18n;
