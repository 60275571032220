<template>
    <div>
        <MainMenuBar />
        <br />
        <div class="columns">
            <div class="column is-one-quarter"></div>
            <router-view class="column" />
            <div class="column is-one-quarter"></div>
        </div>
    </div>
</template>

<script>
import MainMenuBar from "./components/MainMenuBar.vue";

export default {
    name: "App",
    components: {
        MainMenuBar,
    },
};
</script>

<style>
body,
html {
    background-color: #333333;
}

.title {
    color: white;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: white;
    background-color: #333333;
}

.delimiter {
    width: 100%;
    height: 0px;
    border-top: solid 1px white;
    margin: 1em 0;
}

p {
    margin-bottom: 1em;
}
</style>
