<template>
    <div class="has-text-left">
        <div class="delimiter" />
        <p class="title is-5" style="margin-bottom: 0.5em">{{ $t(`${this.i18nName}.name`) }}</p>
        <p class="title is-6">{{ $t(`${this.i18nName}.period`) }}</p>
        <p v-for="p in paragraphNumber" :key="p">{{ $t(`${this.i18nName}.paragraphs.p${p}`) }}</p>
    </div>
</template>

<script>
export default {
    props: {
        i18nName: String,
        paragraphNumber: Number,
    },
};
</script>

<style></style>
