<template>
    <div>
        <p class="title is-1">{{ $t("titles.Vananments", { count: this.vananments.length }) }}</p>
        <div v-for="v in vananments" :key="v">
            <p style="border-bottom: solid 1px white" class="title is-3">{{ numericToRoman(v.id) }}</p>
            <p class="title is-4">{{ v.content }}</p>
        </div>
    </div>
</template>

<script>
export default {
    beforeMount() {
        fetch("https://vanane.net:44300/443/vananment")
            .then((res) => {
                res.text().then((text) => {
                    this.vananments = JSON.parse(text);
                });
            })

            .catch((err) => {
                console.log(err);
                alert("This page is broken right now, come back l8r plz, xoxo.");
            });
    },

    data() {
        return {
            vananments: [],
        };
    },

    methods: {
        numericToRoman(num) {
            if (typeof num !== "number") return null;

            var romans = ["M", "CM", "D", "CD", "C", "XC", "L", "XL", "X", "IX", "V", "IV", "I"];
            var values = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];

            var output = "";

            var i = 0;

            while (num != 0 && i < romans.length) {
                if (values[i] <= num) {
                    num -= values[i];
                    output += romans[i];
                    i = 0;
                } else i++;
            }
            return output;
        },
    },
};
</script>

<style>
.card {
    background-color: #444444;
    margin: 0.5em;
    box-sizing: border-box;
    color: white;
}
</style>
