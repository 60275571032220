import { createApp } from "vue";
import App from "./App.vue";
import router from "./router.js";
import i18n from "./i18n.js";

var locale = localStorage.getItem("locale");

if (locale) i18n.global.locale = locale;
else {
    i18n.global.locale = i18n.global.fallbackLocale;
    localStorage.setItem("locale", i18n.global.fallbackLocale);
}

createApp(App).use(router).use(i18n).mount("#app");
