<template>
    <div>
        <p class="title is-1">{{ $t("titles.About") }}</p>
        <div>:shrug:</div>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
