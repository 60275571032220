import { createRouter, createWebHashHistory } from "vue-router";
import Index from "./pages/Index.vue";
import About from "./pages/About.vue";
import Projects from "./pages/Projects.vue";
import Resume from "./pages/Resume.vue";
import Vananments from "./pages/Vananments.vue";
import NotFound from "./pages/NotFound.vue";

import Portfolio from "./pages/projects/Portfolio.vue";
import Cyberbonk from "./pages/projects/Cyberbonk.vue";

const routes = [
    {
        path: "/",
        name: "Index",
        props: true,
        component: Index,
    },
    {
        path: "/About",
        name: "About",
        props: true,
        component: About,
    },
    {
        path: "/Projects",
        name: "Projects",
        props: true,
        component: Projects,
    },
    {
        path: "/Resume",
        name: "Resume",
        props: true,
        component: Resume,
    },
    {
        path: "/Vananments",
        name: "Vananments",
        props: true,
        component: Vananments,
    },

    /* Projects */
    {
        path: "/Portfolio",
        name: "Portfolio",
        props: true,
        component: Portfolio,
    },
    {
        path: "/Cyberbonk",
        name: "Cyberbonk",
        props: true,
        component: Cyberbonk,
    },

    /* 404 */
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
