<template>
    <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-menu">
            <div class="navbar-start">
                <router-link class="navbar-item" to="/">{{ $t("titles.Home") }}</router-link>
                <router-link class="navbar-item" to="/Projects">{{ $t("titles.Projects") }}</router-link>
                <router-link class="navbar-item" to="/Resume">{{ $t("titles.Resume") }}</router-link>
                <router-link class="navbar-item" to="/Vananments">{{ $t("titles.Vananments") }}</router-link>
                <router-link class="navbar-item" to="/About">{{ $t("titles.About") }}</router-link>
            </div>

            <div class="navbar-end">
                <a class="navbar-item" @click="onFlagClick('en')">🇬🇧</a>
                <a class="navbar-item" @click="onFlagClick('fr')">🇫🇷</a>
                <div class="navbar-item">Vanane.net</div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "MainMenuBar",
    methods: {
        onFlagClick(locale) {
            this.$i18n.locale = locale;
            localStorage.setItem("locale", locale);
        },
    },
};
</script>

<style>
.navbar {
    background-color: chocolate;
}

.navbar * {
    color: white;
}
</style>
